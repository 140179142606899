import React, { createContext } from 'react';

/**
 * @public
 * @name AppContext
 * @description
 * The AppContext created with React.createContext();
 * If you are building a module, you should add this dependency as peerDependency and devDependencies for store uniqueness
 * @type {React.Context<any>}
 */
export const AppContext = createContext();

/**
 * @public
 * @name AppContextConsumer
 * @description
 * It is used for retrieving context values within your application
 * @example
 * <AppContextConsumer>
 *  {({ message }) => <div>{message}</div>}
 * </AppContextConsumer>
 * @type {React.Consumer<any>}
 */
export const AppContextConsumer = AppContext.Consumer;

/**
 * @name AppContextProvider
 * @description
 * AppContextProvider can feed the application context using any props
 */
export default function AppContextProvider(props) {
  const { children, ...contextValues } = props; // eslint-disable-line react/prop-types
  return (
    <AppContext.Provider value={contextValues}>
      {children}
    </AppContext.Provider>
  );
}
